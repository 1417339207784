



















import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import Action from '../../../../store/entities/jobcontext/action'

@Component
export default class JobOverViewData extends AbpBase {
  @Prop({type: Boolean, default: false}) value: boolean;

  private list: any;

  data() {
    return {
      list: []
    }
  }


  cancel() {
    this.$emit('input', false);
  }

  async visibleChange(value: boolean) {
    if (!value) {
      this.$emit('input', value);
    } else {
      let jobId = this.$store.state.jobOverview.jobId
      this.list = await this.$store.dispatch('jobOverview/getJobFieldsMetadata', jobId);
    }
  }

  getColor(isAttached, isRequired) {
    if (isAttached) {
      return '#62ff00';
    } else if (isRequired) {
      return '#ff0000';
    }
    return '#ffd600';

  }
}
